import React from 'react';
export const Nav20DataSource = {
  isScrollLink: true,
  wrapper: { className: 'header2 home-page-wrapper lij4eexrsor-editor_css' },
  page: { className: 'home-page' },
  logo: {
    className: 'header2-logo lij4d4xvn3j-editor_css',
    children:
      'https://firebasestorage.googleapis.com/v0/b/nestwop.appspot.com/o/landing%2Flogo.png?alt=media&token=9d657cac-c16d-4049-a591-3b27dd837739',
  },
  LinkMenu: {
    className: 'header2-menu',
    children: [
      {
        name: 'linkNav',
        to: 'Banner5_0',
        children: 'Home',
        className: 'menu-item lij4akhuek-editor_css',
      },
      {
        name: 'linkNav~lij4bghuuc8',
        to: 'Feature0_1',
        children: 'How It Works',
        className: 'menu-item lij4akhuek-editor_css',
      },
      {
        name: 'linkNav~lij4buzuwr',
        to: 'Pricing0_0',
        children: 'Sign Up',
        className: 'menu-item lij4akhuek-editor_css',
      },
      {
        name: 'linkNav~lij4brzlpu8',
        to: 'Feature8_0',
        children: 'FAQ',
        className: 'menu-item lij4akhuek-editor_css',
      },
    ],
  },
  mobileMenu: { className: 'header2-mobile-menu' },
};
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5 lih6z3rj0vn-editor_css' },
  page: { className: 'home-page banner5-page lih6ziy83c8-editor_css' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>
                Nestw<span>op</span>
              </p>
            </span>
          </span>
        ),
        className: 'banner5-title lih5rcu129b-editor_css',
      },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: (
          <span>
            <span>
              <span>
                <p>Property Swapping for Working Professionals</p>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'banner5-content ln9686nvm2i-editor_css',
        children: (
          <span>
            <span>
              <span>
                <p>
                  A unique platform that connects employees of companies like
                  Google, Amazon, Microsoft and other selected companies for
                  easy and hassle-free property swaps
                </p>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: 'https://platform.nestwop.com/register',
          className: 'banner5-button',
          type: 'primary',
          children: (
            <span>
              <span>
                <p>Get Started with 5 Free Nights</p>
              </span>
            </span>
          ),
        },
      },
    ],
  },
  image: {
    className: 'banner5-image lij4pxh5eg-editor_css',
    children:
      'https://firebasestorage.googleapis.com/v0/b/nestwop.appspot.com/o/landing%2Flanding-image-banner.png?alt=media&token=c8f306aa-ae2e-4c1f-9587-abde0683ef96',
  },
};
export const Feature01DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>How It Works</p>
          </span>
        ),
        className: 'lih8l9rzq6-editor_css',
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper lihjpe0ftl-editor_css',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon lihjlrn4je-editor_css',
              children:
                'https://firebasestorage.googleapis.com/v0/b/nestwop.appspot.com/o/landing%2Flanding-image-house.png?alt=media&token=4b682916-6c12-4d50-879b-5ad0723cdb43',
            },
            {
              name: 'title',
              className: 'content0-block-title lml2powwofm-editor_css',
              children: (
                <span>
                  <p>List Your Property</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <p>
                              Register with your company email and share your
                              property.
                            </p>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              className: 'lml216x7euf-editor_css',
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon lihjlu0sju-editor_css',
              children:
                'https://firebasestorage.googleapis.com/v0/b/nestwop.appspot.com/o/landing%2Flanding-image-luna.png?alt=media&token=7d0b75a9-cbbc-4c88-ad9e-f8adbd8f2a77',
            },
            {
              name: 'title',
              className: 'content0-block-title lml2pi4cgv-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <p>Earn Credits</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>
                      Collect credits to use for stays at other members
                      properties. Earn 1 night credit per night hosted or per
                      referral.
                    </p>
                  </span>
                </span>
              ),
              className: 'lml21b9mg7g-editor_css',
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon lihjlk68u8g-editor_css',
              children:
                'https://firebasestorage.googleapis.com/v0/b/nestwop.appspot.com/o/landing%2Flanding-image-travel.png?alt=media&token=6b4917ca-60f1-401e-a533-e78f4e1ada89',
            },
            {
              name: 'title',
              className: 'content0-block-title lml2pmazkee-editor_css',
              children: (
                <span>
                  <p>Travel and Connect</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>
                    Explore new destinations and connect with fellow
                    professionals.
                  </p>
                </span>
              ),
              className: 'lml21g55nkm-editor_css',
            },
          ],
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: {
    className: 'home-page-wrapper content5-wrapper lij5ezo326-editor_css',
  },
  page: { className: 'home-page content5 lij5emqc9y-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Exclusive Network of Professionals</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content lij547v6jzu-editor_css',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            <b>
                              Nestwop is currently open only to employees of
                              Google, Apple, Microsoft, and other selected
                              companies ensuring a curated network of
                              like-minded professionals.
                            </b>
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://firebasestorage.googleapis.com/v0/b/nestwop.appspot.com/o/landing%2Flanding-image-google.png?alt=media&token=199e86f7-8abb-4562-ad39-3a4f287cfbad',
            className: 'lij59zwn77s-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>Google</p>
                </span>
              </span>
            ),
            className: 'lml25di7h6l-editor_css',
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://firebasestorage.googleapis.com/v0/b/nestwop.appspot.com/o/landing%2Flanding-image-apple.png?alt=media&token=b8ed84ab-0716-456d-883e-442707e84fae',
            className: 'lij5b1es27q-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>Apple</p>
                </span>
              </span>
            ),
            className: 'lml261ti6ya-editor_css',
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              'https://firebasestorage.googleapis.com/v0/b/nestwop.appspot.com/o/landing%2Flanding-image-microsoft.png?alt=media&token=8749c78c-2298-471e-a2cb-0d993d4e589e',
            className: 'lij5d10uw3-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <p>Microsoft</p>
                  </span>
                </span>
              </span>
            ),
            className: 'lml265e2osa-editor_css',
          },
        },
      },
      {
        name: 'block~ls3orcpz249',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content',
            href:
              'https://nestwop.notion.site/Allowlisted-Companies-edfb01feb1c846eda6f8b6141b557baa',
            target: '_blank',
            rel: 'noopener noreferrer'
          },
          img: {
            children:
              'https://firebasestorage.googleapis.com/v0/b/nestwop.appspot.com/o/landing%2Flading-page-other-logos.png?alt=media&token=8fbea503-9ebd-4adf-afc3-9ab7211cd3af',
            className: 'lij5d10uw3-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>
                        <a
                          href="https://nestwop.notion.site/Allowlisted-Companies-edfb01feb1c846eda6f8b6141b557baa"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          + 38 Others
                        </a>
                      </p>
                    </span>
                  </span>
                </span>
              </span>
            ),
            className: 'lml265e2osa-editor_css',
          },
        },
      },
    ],
  },
};
export const Pricing00DataSource = {
  wrapper: { className: 'home-page-wrapper pricing0-wrapper' },
  OverPack: { playScale: 0.3, className: 'home-page pricing0', gutter: 0 },
  imgWrapper: { className: 'pricing0-img-wrapper', md: 12, xs: 24 },
  img: {
    className: 'pricing0-img lim5tih04ej-editor_css',
    name: 'image',
    children:
      'https://firebasestorage.googleapis.com/v0/b/nestwop.appspot.com/o/landing%2Flanding-image-laptop.png?alt=media&token=5e680e05-4aae-42d8-abbd-d753a805ef5a',
  },
  childWrapper: {
    className: 'pricing0-text-wrapper',
    md: 12,
    xs: 24,
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <p>
                  Join Our Alpha<span>: Free Access</span>
                </p>
              </span>
            </span>
          </span>
        ),
        className: 'pricing0-title lml26ilmmaq-editor_css',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p>
                      Sign up now to enjoy free access <b>forever </b>to our
                      platform as we continue to refine and expand our services.
                    </p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'pricing0-content lml26lxk7t-editor_css',
      },
      {
        name: 'pricing',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>FREE</p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'pricing0-pricing lgwn6f40wzs-editor_css',
      },
      {
        name: 'button',
        children: {
          href: 'https://platform.nestwop.com/register',
          type: 'primary',
          children: (
            <span>
              <span>
                <p>Sign Up</p>
              </span>
            </span>
          ),
          className: 'lmnc8rhkq77-editor_css',
        },
      },
    ],
  },
};
export const Feature80DataSource = {
  wrapper: { className: 'home-page-wrapper feature8-wrapper' },
  page: { className: 'home-page feature8' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature8-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature8-title-h1',
        children: (
          <span>
            <span>
              <p>Frequently Asked Questions</p>
            </span>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'feature8-button-wrapper',
    children: [
      {
        name: 'button',
        className: 'feature8-button',
        children: {
          href: 'https://platform.nestwop.com/register',
          children: (
            <span>
              <span>
                <p>Sign Up</p>
                <p>
                  <br />
                </p>
              </span>
            </span>
          ),
        },
      },
    ],
  },
  Carousel: {
    dots: false,
    className: 'feature8-carousel',
    wrapper: { className: 'feature8-block-wrapper' },
    children: {
      className: 'feature8-block',
      titleWrapper: {
        className: 'feature8-carousel-title-wrapper',
        title: { className: 'feature8-carousel-title' },
      },
      children: [
        {
          name: 'elem~lgwlquzbdaq',
          className: 'feature8-block-row lmnbxtun9m-editor_css',
          gutter: 20,
          title: {
            className: 'feature8-carousel-title-block',
            children: '平台自主训练流程',
          },
          children: [
            {
              className: 'feature8-block-col lij5mqvujve-editor_css',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'lmnchc5sapp-editor_css feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <p>How do I sign up?</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content lgwlrjysqw-editor_css',
                    children: (
                      <span>
                        <p>
                          Simply sign up using your company email, and you'll
                          receive 5 free credits upon joining.
                        </p>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col lml2ewjqe1b-editor_css ant-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child lml2he9uw29-editor_css',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <p>How does the credit system work?</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content lgwlrwozyp-editor_css',
                    children: (
                      <span>
                        <p>
                          Earn 1 night credit for each night you share your
                          property or per referral. Use these credits to book
                          stays at other members' properties.
                        </p>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child lgwmjqus8ub-editor_css',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title',
                    children: (
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>
                                Will I have to pay if I sign up during the
                                alpha?
                              </p>
                            </span>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: (
                      <span>
                        <span>
                          <span>
                            <p>
                              No, users who join Nestwop during our alpha phase
                              will enjoy free access to our services forever.
                            </p>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col lgwn82u5js-editor_css',
              md: 6,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child lgwn8m7r0ar-editor_css',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://gw.alipayobjects.com/zos/basement_prod/d8933673-1463-438a-ac43-1a8f193ebf34.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title lgwmkjs44vm-editor_css',
                    children: (
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <span>
                                      <span>
                                        <span>
                                          <span>
                                            <p>
                                              &nbsp;Can I still join if I don't
                                              work for any of the listed
                                              companies?
                                            </p>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content',
                    children: (
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <span>
                                    <p>
                                      If you're interested in joining Nestwop,
                                      please express your interest{' '}
                                      <a
                                        href="https://noteforms.com/forms/nestwop-join-our-global-property-swap-community-syw6o1"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        here
                                      </a>{' '}
                                      as we are expanding the list of eligible
                                      companies.
                                    </p>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>
          ©2023&nbsp;Nestwop&nbsp;All Rights Reserved&nbsp;|&nbsp;<a
            href="https://nestwop.notion.site/Terms-of-Service-0f4b79c049804d8f85cc0bc8bfabb69b?pvs=4"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </a>{' '}
          |{' '}
          <a
            href="https://nestwop.notion.site/Nestwop-Privacy-Policy-c2f83409b5c64e479b334c42a279fbd4?pvs=4"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </a>{' '}
          | <a href="mailto:hello@nestwop.com">Contact Us&nbsp;</a>
          <br />
        </span>
      </span>
    ),
  },
};
